function ricette_toString(val, removeSpecialChars = true) {
	if (val === undefined || val === null) {
		return '';
	} else {
		if (removeSpecialChars) {
			return (val + '').replace(/(?:\r\n|\r|\n)/g, " ");
		} else {
			return val
		}
	}
}

function RicettaPdfGenerator(data) {
	this.pdf = new PdfGenerator(data, {
		orientation: 'portrait',
		unit: 'cm',
		format: 'a4'
	});

	// aggiungo le labels per la stampa
	data['label1'] = 'SERVIZIO SANITARIO NAZIONALE';
	data['label2'] = 'RICETTA ELETTRONICA - PROMEMORIA PER L\'ASSISTITO';
	data['label3'] = 'COGNOME E NOME/INIZIALI DELL\'ASSISTITO: ';
	data['label4'] = 'INDIRIZZO';
	data['label5'] = 'CAP';
	data['label6'] = 'CITTA\': ';
	data['label7'] = 'PROV.: ';
	data['label8'] = 'ESENZIONE: ';
	data['label9'] = 'SIGLA PROVINCIA: ';
	data['label10'] = 'CODICE ASL: ';
	data['label11'] = 'DISPOSIZIONI REGIONALI: ';
	data['label12'] = 'TIPOLOGIA PRESCRIZIONE: ';
	data['label13'] = 'ALTRO: ';
	data['label14'] = 'PRIORITA\' PRESCRIZIONE (U, B, D, P): ';
	data['label15'] = 'QUESITO DIAGNOSTICO: ';
	data['label16'] = 'N. CONFEZIONI/PRESTAZIONI: ';
	data['label17'] = 'TIPO RICETTA: ';
	data['label18'] = 'DATA: ';
	data['label19'] = 'CODICE FISCALE DEL MEDICO: ';
	data['label20'] = 'CODICE AUTENTICAZIONE: ';
	data['label21'] = 'COGNOME E NOME DEL MEDICO: ';
	data['label22'] = 'Rilasciato ai sensi dell\'Art. 11, comma 16 del DL 31 mag 2010, n. 78 e dell\'Art. 1, comma 4 del DM 2 nov 2011';
	data['label23'] = 'PRESCRIZIONE';
	data['label24'] = 'QTA';
	data['label25'] = 'NOTA';

	this.barcodeEl = '#barcode';

	this.startLeft = .7;
	this.maxLength = 19.7;

	this.loadRemoteData = async function (url, params) {
		return this.pdf.loadRemoteData(url, params);
	};

	this.generaRicetta = function (callback) {

		if (this.pdf === null) {
			return;
		}

		// testata
		this.pdf.text(ricette_toString(data.label1), this.startLeft, .6);
		this.pdf.text(ricette_toString(data.label2), 13.2, .6);

		this.pdf.rect(this.startLeft, .8, 5.7, 1.5);
		this.pdf.text(ricette_toString(data.regione), 2.9, 1.6);

		this.pdf.rect(6.4, .8, 13.9, 1.5);

		this.pdf.barcode(this.barcodeEl, ricette_toString(data.nre).substring(0, 5), 8.6, 1, 4, 1);
		this.pdf.text('*' + ricette_toString(data.nre).substr(0, 5) + '*', 10.3, 2.2);

		this.pdf.barcode(this.barcodeEl, ricette_toString(data.nre.substring(5, 15)), 13.3, 1, 6.5, 1);
		this.pdf.text('*' + ricette_toString(data.nre).substr(5, 15) + '*', 15.8, 2.2);

		this.pdf.text(ricette_toString(data.label3) + ricette_toString(data.nomepaz), this.startLeft, 2.6);
		this.pdf.text(ricette_toString(data.label4) + ricette_toString(data.indirizzopaz), this.startLeft, 3);
		this.pdf.text(ricette_toString(data.label5) + ricette_toString(data.cappaz), this.startLeft, 3.4);
		this.pdf.text(ricette_toString(data.label6) + ricette_toString(data.cittapaz), 2.5, 3.4);
		this.pdf.text(ricette_toString(data.label7) + ricette_toString(data.provinciapaz), 7.7, 3.4);

		this.pdf.barcode(this.barcodeEl, ricette_toString(data.codfispaz), 11.6, 2.4, 8.2, 1);
		this.pdf.text('*' + ricette_toString(data.codfispaz) + '*', 14.5, 3.6);

		this.pdf.line(this.startLeft, 3.8, this.maxLength + .7, 3.8);

		let esenzione = ricette_toString(data.codesenzione);
		if (esenzione === '') {
			esenzione = 'NON ESENTE';
		}

		this.pdf.text(ricette_toString(data.label8) + ricette_toString(esenzione), this.startLeft, 4.2);
		this.pdf.text(ricette_toString(data.label9) + ricette_toString(data.provinciaasl), 4.9, 4.2);
		this.pdf.text(ricette_toString(data.label10) + ricette_toString(data.codasl), 10.5, 4.2);
		this.pdf.text(ricette_toString(data.label11) + ricette_toString(data.disposizioniRegionali), 15.8, 4.2);

		this.pdf.text(ricette_toString(data.label12) + ricette_toString(data.suggerita), this.startLeft, 4.6);
		this.pdf.text(ricette_toString(data.label13) + ricette_toString(data.altro), 4.9, 4.6);
		this.pdf.text(ricette_toString(data.label14) + ricette_toString(data.priorita), 8.4, 4.6);

		// dettaglio
		this.pdf.rect(this.startLeft, 4.8, this.maxLength, .5);
		this.pdf.text(ricette_toString(data.label23), 9, 5.1);
		this.pdf.line(17.6, 4.8, 17.6, 5.5);
		this.pdf.text(ricette_toString(data.label24), 18, 5.1);
		this.pdf.line(19, 4.8, 19, 5.5);
		this.pdf.text(ricette_toString(data.label25), 19.4, 5.1);

		let offset;
		let altezzaRighe;
		if (data.prest.length > 7) {
			offset = 0;
			altezzaRighe = .95;
		} else {
			offset = 0;
			altezzaRighe = 1;
		}

		let me = this;
		data.prest.forEach(prestazione => {
			// rettangolo della riga
			me.pdf.rect(me.startLeft, 5.3 + offset, me.maxLength, altezzaRighe);
			// linea verticale prescrizione - quantita
			me.pdf.line(17.6, 5.3 + offset, 17.6, 5.3 + altezzaRighe + offset);
			// linea vertivale quantita - nota
			me.pdf.line(19, 5.3 + offset, 19, 5.3 + altezzaRighe + offset);

			// gestisco il testo multilinea
			let prescrizione = ricette_toString(prestazione.prestazione);
			let testoaggiuntivo = ricette_toString(prestazione.testoaggiuntivo);

			prescrizione = prescrizione
				.replace(/\*\*\*.*\*\*\* - /, '')
				.replace(/\*\*\*.*\*\*\*/, '')
			testoaggiuntivo = testoaggiuntivo
				.replace(/\*\*\*.*\*\*\* - /, '')
				.replace(/\*\*\*.*\*\*\*/, '')

			if (prescrizione.length > 106) {
				let testo = me.pdf.splitText(prescrizione + ' ' + testoaggiuntivo, 16.5);
				me.pdf.text(testo, me.startLeft + .1, 5.1 + altezzaRighe + offset - .5);
			} else {
				let splitPrescrizione = me.pdf.splitText(prescrizione, 16.5);
				let splitTestoAggiuntivo = me.pdf.splitText(testoaggiuntivo, 16.5);

				me.pdf.text(ricette_toString(splitPrescrizione), me.startLeft + .1, 5.1 + altezzaRighe + offset - .5);
				me.pdf.text(ricette_toString(splitTestoAggiuntivo), me.startLeft + .1, 5.4 + altezzaRighe + offset - .5);
			}

			me.pdf.text(ricette_toString(prestazione.qta) + '', 18.15, 5.3 + altezzaRighe + offset - .3);
			me.pdf.text(ricette_toString(prestazione.nota) + '', 19.6, 5.3 + altezzaRighe + offset - .3);

			offset += altezzaRighe;
		});

		// footer
		me.pdf.text(ricette_toString(data.label15) + ricette_toString(data.diagnosi), this.startLeft, 13.2);

		me.pdf.text(ricette_toString(data.label16) + ricette_toString(data.qtatot), this.startLeft, 13.6);
		//  me.pdf.text(ricette_toString(data.label17) + ricette_toString(data.tipoRicetta), 5.3, 13.6);
		// il tipo ricetta è fisso
		me.pdf.text(ricette_toString(data.label17) + "Assist. SSN", 5.3, 13.6);
		me.pdf.text(ricette_toString(data.label18) + ricette_toString(data.dataricetta), 9, 13.6);
		me.pdf.text(ricette_toString(data.label19) + ricette_toString(data.codfismedico), 12.3, 13.6);

		me.pdf.text(ricette_toString(data.label20) + ricette_toString(data.codautenticazione), this.startLeft, 14);
		me.pdf.text(ricette_toString(data.label21) + ricette_toString(data.nomemedico), 9, 14);

		me.pdf.text(ricette_toString(data.label22), 4.5, 14.4);

		if (callback) {
			// this.pdf.save(name);
			callback(this.pdf.output('datauristring'));
		} else {
			this.pdf.save("ricetta.pdf");
		}
	};
}

function NREListPdfGenerator(data) {
	this.pdf = new PdfGenerator(data, {
		orientation: 'portrait',
		unit: 'cm',
		format: 'a4'
	});

	this.barcodeEl = '#barcodeNRE';

	this.startLeft = 1;
	this.maxLength = 19.7;

	this.genera = function (callback) {

		if (this.pdf === null) {
			return;
		}
		this.pdf.setFontSize(10);

		this.pdf.text('CODICE FISCALE:', this.startLeft, 2);

		this.pdf.barcode(this.barcodeEl, ricette_toString(data.codiceFiscale), 5, 1.5, 6, 1);
		this.pdf.text(ricette_toString(data.codiceFiscale).slice(0, 6) + '**********', 16, 2);

		this.pdf.setFontSize(14);
		this.pdf.text('NRE:', this.startLeft, 4);

		let offset = 1;
		let altezzaRighe = 2;
		data.farmaci.forEach(farmaco => {
			this.pdf.text(farmaco.nre, this.startLeft, 4.5 + offset);
			this.pdf.barcode(this.barcodeEl, farmaco.nre.substring(0, 5), this.startLeft + 6, 3.8 + offset, 6, 1);
			this.pdf.barcode(this.barcodeEl, farmaco.nre.substring(5), this.startLeft + 12, 3.8 + offset, 6, 1);
			offset += altezzaRighe;
		});

		if (callback) {
			// this.pdf.save(name);
			callback(this.pdf.output('datauristring'));
		} else {
			this.pdf.save("nre.pdf");
		}
	};
}

/**
 * Semplice classe per le stampe.
 * Richiede che nella pagina html sia presente (anche invisibile con dimensioni (0,0)) un elemento 'idEl'
 * per la stampa dei barcode.
 * @param data
 * @param customInitParams
 * @constructor
 */
function PdfGenerator(data, customInitParams = null) {

	this.defaultFontFamily = "arial";
	this.defaultFontSize = 7;

	this.orientation = 'landscape';
	this.unit = 'cm';

	this.pageWidth = 14.78;
	this.pageHeight = 21;

	this.lineWidth = 0.03;

	this.data = data;

	this.text = function (text, left, top) {
		return this.doc.text(text, left, top);
	};

	this.setFont = function (name) {
		return this.doc.setFont(name);
	};
	this.setFontSize = function (name) {
		return this.doc.setFontSize(name);
	};
	this.rect = function (left, top, width, height) {
		return this.doc.rect(left, top, width, height);
	};
	this.line = function (left, top, width, height) {
		return this.doc.line(left, top, width, height);
	};
	this.setLineWidth = function (lw) {
		return this.doc.setLineWidth(lw);
	};
	this.save = function (name) {
		return this.doc.save(name);
	};
	this.output = function (type, win) {
		return this.doc.output(type, win);
	};
	this.barcode = function (idEl, text, left, top, width, height) {
		JsBarcode(idEl, text, { format: 'CODE39', displayValue: false }).render();
		let barcodeUrl = $(idEl)[0].toDataURL("image/svg");
		return this.image(barcodeUrl, left, top, width, height);
		// return this.svg(document.querySelector(idEl), left, top, width, height);
	};
	this.svg = function (img, left, top, width, height) {
		let svgAsText = new XMLSerializer().serializeToString(img);
		return this.doc.addSvgAsImage(svgAsText, left, top, width, height, '', 'MEDIUM', 0);
	};
	this.image = function (img, left, top, width, height) {
		return this.doc.addImage(img, 'svg', left, top, width, height);
	};
	this.splitText = function (text, len) {
		return this.doc.splitTextToSize(text, len);
	};


	this.newPdfObject = function () {
		if (this.data === null || this.data === undefined) {
			alert('Attenzione, dati mancanti per la generazione del documento');
			return null;
		}

		let doc;
		if (customInitParams) {
			// FIXME: migliorare
			doc = new jsPDF(customInitParams.orientation, customInitParams.unit, customInitParams.format);
		} else {
			doc = new jsPDF(this.orientation, this.unit, [this.pageWidth, this.pageHeight]);
		}

		doc.setFont(this.defaultFontFamily);
		doc.setFontSize(this.defaultFontSize);
		doc.setLineWidth(this.lineWidth);

		return doc;
	};

	this.doc = this.newPdfObject();

	this.loadRemoteData = async function (url, params) {
		let me = this;
		this.fetch(url, params).then(res => {
			this.data = res.json();
			return me;
		});
	}
}
