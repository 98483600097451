// moduli.service.ts
// (c) 2017 EvoluS Srl
// (a) VG

function CertificatoInpsGenerator(dati) {

    // pulisco i dati da null e undefined
    Object.keys(dati).forEach(key => {
        if (dati[key] === null || dati[key] === undefined) {
            dati[key] = "";
        }
    });

    this.dati = dati;
    this.fontDescriptors = {
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf'
        },
        Times: {
            normal: 'times.ttf',
            bold: 'timesbd.ttf',
            italics: 'timesi.ttf',
            bolditalics: 'timesbi.ttf'
        },
        Arial: {
            normal: 'arial.ttf',
            bold: 'arialbd.ttf',
            italics: 'ariali.ttf',
            bolditalics: 'arialbi.ttf'
        },
        Bar3of9: {
            normal: 'fre3of9x.ttf',
            bold: 'fre3of9x.ttf',
            italics: 'fre3of9x.ttf',
            bolditalics: 'fre3of9x.ttf'
        }
    };
    this.tableLayout = {
        hLineWidth: () => { return 0.7 },
        vLineWidth: () => { return 0.7 }
    };
    this.borderNoUp = [true, false, true, true];
    this.borderNone = [false, false, false, false];
    this.squareUnchecked = {
        canvas: [{
            type: 'rect',
            lineWidth: 0.7,
            x: 0, y: 1, w: 7, h: 7
        }]
    };
    this.squareChecked = {
        canvas: [{
            type: 'rect',
            lineWidth: 0.7,
            x: 0, y: 1, w: 7, h: 7
        },
        {
            type: 'line',
            lineWidth: 0.7,
            x1: 0, y1: 1, x2: 7, y2: 8
        },
        {
            type: 'line',
            lineWidth: 0.7,
            x1: 7, y1: 1, x2: 0, y2: 8
        }
        ]
    };
    this.isChecked = (flag) => {
        if (flag) {
            return JSON.parse(JSON.stringify(this.squareChecked))
        }
        else {
            return JSON.parse(JSON.stringify(this.squareUnchecked))
        }
    };
    this.contentCertificato = (dati) => {
        return [
            // [0]
            {
                table: {
                    widths: ['50%', '10%', '10%', '30%'],
                    body: [
                        [
                            { text: 'Certificato di malattia telematico', colSpan: 3, style: 'header1' },
                            {},
                            {},
                            { text: 'Copia cartacea per il lavoratore', fontSize: 9 }
                        ],
                        [
                            { text: 'Numero di protocollo univoco del certificato (PUC)' },
                            { text: dati.numpuc },
                            { text: 'Data visita' },
                            { text: moment(dati.datavisita).format('DD/MM/YYYY') }
                        ],
                        [
                            { text: 'Rettifica del certificato (Numero di protocollo univoco del certificato rettificato)', colSpan: 3 },
                            {},
                            {},
                            {}
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [1]
            { text: '', margin: [0, 0, 0, 5] },
            // [2]
            {
                table: {
                    widths: ['10%', '30%', '10%', '8%', '10%', '8%', '16%', '8%'],
                    body: [
                        [
                            { text: ['D', { text: 'ATI DEL ', fontSize: 8 }, 'M', { text: 'EDICO', fontSize: 8 }], colSpan: 8, style: 'header2' },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {}
                        ],
                        [
                            { text: 'Cognome\ne nome' },
                            { text: dati.medico },
                            { text: 'Codice Regione' },
                            { text: dati.codregione },
                            { text: 'Codice\nASL/AO' },
                            { text: dati.codasl },
                            { text: 'Codice struttura\nricovero' },
                            { text: dati.codstrutt }
                        ],
                    ]
                },
                layout: this.tableLayout
            },
            // [3]
            {
                table: {
                    widths: ['20%', '40%', '40%'],
                    body: [
                        [
                            { text: 'Opera nel ruolo di:', border: this.borderNoUp },
                            { columns: [{ text: 'Medico SSN', width: 55 }, this.isChecked(dati.flagSsn)], border: this.borderNoUp },
                            { columns: [{ text: 'Libero professionista', width: 80 }, this.isChecked(dati.flagLib)], border: this.borderNoUp }
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [4]
            { text: '', margin: [0, 0, 0, 5] },
            // [5]
            {
                table: {
                    widths: ['40%', '15%', '30%', '15%'],
                    body: [
                        [
                            { text: ['D', { text: 'ATI ', fontSize: 8 }, 'P', { text: 'ROGNOSI', fontSize: 8 }], colSpan: 4, style: 'header2' },
                            {},
                            {},
                            {}
                        ],
                        [
                            { text: 'Il lavoratore dichiara di essere ammalato dal' },
                            { text: moment(dati.prognosidal).format('DD/MM/YYYY') },
                            { text: 'Viene assegnata prognosi clinica a tutto il' },
                            { text: moment(dati.prognosial).format('DD/MM/YYYY') }
                        ],
                        [
                            { columns: [{ text: 'Il lavoratore dichiara di aver completato la propria attività lavorativa alla data di visita', width: 290, border: this.borderNoUp }, this.isChecked(dati.flagLav)], colSpan: 4 }
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [6]
            {
                table: {
                    widths: ['10%', '15%', '15%', '25%', '35%'],
                    body: [
                        [
                            { text: 'Trattasi di:', border: this.borderNoUp },
                            {
                                columns:
                                    [
                                        { text: 'Inizio', width: 30 },
                                        this.isChecked(dati.flagInizio)
                                    ],
                                border: this.borderNoUp
                            },
                            { columns: [{ text: 'Continuazione', width: 58 }, this.isChecked(dati.flagContinuazione)], border: this.borderNoUp },
                            { columns: [{ text: 'Ricaduta', width: 45 }, this.isChecked(dati.flagRicaduta)], border: this.borderNoUp, colSpan: 2 },
                            {}
                        ],
                        [
                            { text: 'Visita:' },
                            { columns: [{ text: 'Ambulatoriale', width: 52 }, this.isChecked(dati.flagAmbulatoriale)], colSpan: 2 },
                            {},
                            { columns: [{ text: 'Domiciliare', width: 46 }, this.isChecked(dati.flagDomiciliare)] },
                            { columns: [{ text: 'Pronto Soccorso', width: 60 }, this.isChecked(dati.flagPs)] }
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [7]
            { text: '', margin: [0, 0, 0, 5] },
            // [8]
            {
                table: {
                    widths: [120, 100, '*'],
                    body: [
                        [
                            { text: ['D', { text: 'ATI ', fontSize: 8 }, 'D', { text: 'IAGNOSI', fontSize: 8 }], colSpan: 3, style: 'header2' },
                            {},
                            {}
                        ],
                        [
                            { text: 'Cod nosologico' },
                            { text: dati.codiceicd },
                            { columns: [{ text: 'La malattia è dovuta ad evento traumatico', width: 140 }, this.isChecked(dati.flagTrauma)] }
                        ],
                        [
                            { text: 'Diagnosi' },
                            { text: dati.testodiagnosi, colSpan: 2 },
                            {}
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [9]
            {
                table: {
                    widths: ['30%', '35%', '35%'],
                    body: [
                        [
                            { columns: [{ text: 'Patologia grave che richiede\nterapia salvavita', width: 135 }, this.isChecked(dati.flagSalvavita)], border: this.borderNoUp },
                            { columns: [{ text: 'Malattia per la quale è stata riconosciuta\nla causa di servizio', width: 155 }, this.isChecked(dati.flagCausa)], border: this.borderNoUp },
                            { columns: [{ text: 'Stato patologico sotteso o connesso alla\nsituazione di invalidità riconosciuta', width: 155 }, this.isChecked(dati.flagInvalidita)], border: this.borderNoUp }
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [10]
            { text: '', margin: [0, 0, 0, 5] },
            // [11]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: ['D', { text: 'ATI DEL ', fontSize: 8 }, 'L', { text: 'AVORATORE', fontSize: 8 }], style: 'header2' }],
                        [{ text: ['A', { text: 'NAGRAFICI', fontSize: 8 }], style: 'header3' }]
                    ]
                },
                layout: this.tableLayout
            },
            // [12]
            {
                table: {
                    widths: [55, '*', '8%', '28%', '5%', '17%'],
                    body: [
                        [{ text: 'Cognome', border: this.borderNoUp }, { text: dati.cognome, border: this.borderNoUp }, { text: 'Nome', border: this.borderNoUp }, { text: dati.nome, border: this.borderNoUp }, { text: 'C.F.', border: this.borderNoUp }, { text: dati.codfis, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [13]
            {
                table: {
                    widths: [55, '*', '22%', '32%', '8%', '10%'],
                    body: [
                        [{ text: 'Nato/a il', border: this.borderNoUp }, { text: moment(dati.natoil).format('DD/MM/YYYY'), border: this.borderNoUp }, { text: ['a ', { text: '(Comune o Stato estero)', italics: true }], border: this.borderNoUp }, { text: dati.natoa, border: this.borderNoUp }, { text: 'Provincia:', border: this.borderNoUp }, { text: dati.provnasc, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [14]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: ['R', { text: 'ESIDENZA O DOMICILIO ABITUALE (dati dichiarati dal lavoratore)', fontSize: 8 }], border: this.borderNoUp, style: 'header3' }],
                    ]
                },
                layout: this.tableLayout
            },
            // [15]
            {
                table: {
                    widths: [55, '*', '3%', '25%'],
                    body: [
                        [{ text: 'In via/piazza', border: this.borderNoUp }, { text: dati.indires, border: this.borderNoUp }, { text: 'n.', border: this.borderNoUp }, { text: dati.numres, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [16]
            {
                table: {
                    widths: [55, '*', '8%', '20%', '12%', '8%'],
                    body: [
                        [{ text: 'Comune', border: this.borderNoUp }, { text: dati.cittares, border: this.borderNoUp }, { text: 'CAP', border: this.borderNoUp }, { text: dati.capres, border: this.borderNoUp }, { text: 'Provincia', border: this.borderNoUp }, { text: dati.provres, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [17]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: ['R', { text: 'EPERIBILITÀ DURANTE LA MALATTIA (dati dichiarati dal lavoratore - da indicare solo se diversi da quelli di residenza o domicilio abituale riportati)', fontSize: 8 }], border: this.borderNoUp, style: 'header3' }]
                    ]
                },
                layout: this.tableLayout
            },
            // [18]
            {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [{ text: ['Nominativo indicato presso l\'abitazione ', { text: '(se diverso dal proprio)', italics: true }], border: this.borderNoUp }, { text: dati.nomealt, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [19]
            {
                table: {
                    widths: [55, '*', '3%', '25%'],
                    body: [
                        [{ text: 'In via/piazza', border: this.borderNoUp }, { text: dati.indidom, border: this.borderNoUp }, { text: 'n.', border: this.borderNoUp }, { text: dati.numdom, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [20]
            {
                table: {
                    widths: [55, '*', '8%', '20%', '12%', '8%'],
                    body: [
                        [{ text: 'Comune', border: this.borderNoUp }, { text: dati.cittadom, border: this.borderNoUp }, { text: 'CAP', border: this.borderNoUp }, { text: dati.capdom, border: this.borderNoUp }, { text: 'Provincia', border: this.borderNoUp }, { text: dati.provdom, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [21]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: 'Rilasciato ai sensi del DPCM 26 marzo 2008 e del Dlgs n. 250/2009', italics: true, fontSize: 10, bold: true, alignment: 'center', border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            }
        ]
    };
    this.contentAttestato = (dati) => {
        return [
            // [0]
            {
                table: {
                    widths: ['50%', '10%', '10%', '30%'],
                    body: [
                        [
                            { text: 'Attestato di malattia telematico', colSpan: 3, style: 'header1' },
                            {},
                            {},
                            { text: 'Copia cartacea per il datore di lavoro', fontSize: 9 }
                        ],
                        [
                            { text: 'Numero di protocollo univoco del certificato (PUC)' },
                            { text: dati.numpuc },
                            { text: 'Data visita' },
                            { text: moment(dati.datavisita).format('DD/MM/YYYY') }
                        ],
                        [
                            { text: 'Rettifica del certificato (Numero di protocollo univoco del certificato rettificato)', colSpan: 3 },
                            {},
                            {},
                            {}
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [1]
            { text: '', margin: [0, 0, 0, 5] },
            // [2]
            {
                table: {
                    widths: ['10%', '30%', '10%', '8%', '10%', '8%', '16%', '8%'],
                    body: [
                        [
                            { text: ['D', { text: 'ATI DEL ', fontSize: 8 }, 'M', { text: 'EDICO', fontSize: 8 }], colSpan: 8, style: 'header2' },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {}
                        ],
                        [
                            { text: 'Cognome\ne nome' },
                            { text: dati.medico },
                            { text: 'Codice Regione' },
                            { text: dati.codregione },
                            { text: 'Codice\nASL/AO' },
                            { text: dati.codasl },
                            { text: 'Codice struttura\nricovero' },
                            { text: dati.codstrutt }
                        ],
                    ]
                },
                layout: this.tableLayout
            },
            // [3]
            {
                table: {
                    widths: ['20%', '40%', '40%'],
                    body: [
                        [
                            { text: 'Opera nel ruolo di:', border: this.borderNoUp },
                            { columns: [{ text: 'Medico SSN', width: 55 }, this.isChecked(dati.flagSsn)], border: this.borderNoUp },
                            { columns: [{ text: 'Libero professionista', width: 80 }, this.isChecked(dati.flagLib)], border: this.borderNoUp }
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [4]
            { text: '', margin: [0, 0, 0, 5] },
            // [5]
            {
                table: {
                    widths: ['40%', '15%', '30%', '15%'],
                    body: [
                        [
                            { text: ['D', { text: 'ATI ', fontSize: 8 }, 'P', { text: 'ROGNOSI', fontSize: 8 }], colSpan: 4, style: 'header2' },
                            {},
                            {},
                            {}
                        ],
                        [
                            { text: 'Il lavoratore dichiara di essere ammalato dal' },
                            { text: moment(dati.prognosidal).format('DD/MM/YYYY') },
                            { text: 'Viene assegnata prognosi clinica a tutto il' },
                            { text: moment(dati.prognosial).format('DD/MM/YYYY') }
                        ],
                        [
                            { columns: [{ text: 'Il lavoratore dichiara di aver completato la propria attività lavorativa alla data di visita', width: 290, border: this.borderNoUp }, this.isChecked(dati.flagLav)], colSpan: 4 }
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [6]
            {
                table: {
                    widths: ['10%', '15%', '15%', '25%', '35%'],
                    body: [
                        [
                            { text: 'Trattasi di:', border: this.borderNoUp },
                            {
                                columns:
                                    [
                                        { text: 'Inizio', width: 30 },
                                        this.isChecked(dati.flagInizio)
                                    ],
                                border: this.borderNoUp
                            },
                            { columns: [{ text: 'Continuazione', width: 58 }, this.isChecked(dati.flagContinuazione)], border: this.borderNoUp },
                            { columns: [{ text: 'Ricaduta', width: 45 }, this.isChecked(dati.flagRicaduta)], border: this.borderNoUp, colSpan: 2 },
                            {}
                        ],
                        [
                            { text: 'Visita:' },
                            { columns: [{ text: 'Ambulatoriale', width: 52 }, this.isChecked(dati.flagAmbulatoriale)], colSpan: 2 },
                            {},
                            { columns: [{ text: 'Domiciliare', width: 46 }, this.isChecked(dati.flagDomiciliare)] },
                            { columns: [{ text: 'Pronto Soccorso', width: 60 }, this.isChecked(dati.flagPs)] }
                        ],
                        [
                            { columns: [{ text: 'La malattia è dovuta ad evento traumatico', width: 140 }, this.isChecked(dati.flagTrauma)], colSpan: 3 },
                            {},
                            {},
                            { columns: [{ text: 'Malattia per la quale è stata riconosciuta la causa di servizio', width: 200 }, this.isChecked(dati.flagCausa)], colSpan: 2 },
                            {}
                        ],
                        [
                            { columns: [{ text: 'Patologia grave che richiede terapia salvavita', width: 140 }, this.isChecked(dati.flagSalvavita)], colSpan: 3 },
                            {},
                            {},
                            { columns: [{ text: 'Stato patologico sotteso o connesso alla situazione di invalidità riconosciuta', width: 250 }, this.isChecked(dati.flagInvalidita)], colSpan: 2 },
                            {}
                        ]
                    ]
                },
                layout: this.tableLayout
            },
            // [7]
            { text: '', margin: [0, 0, 0, 5] },
            // [8]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: ['D', { text: 'ATI DEL ', fontSize: 8 }, 'L', { text: 'AVORATORE', fontSize: 8 }], style: 'header2' }],
                        [{ text: ['A', { text: 'NAGRAFICI', fontSize: 8 }], style: 'header3' }]
                    ]
                },
                layout: this.tableLayout
            },
            // [9]
            {
                table: {
                    widths: [55, '*', '8%', '28%', '5%', '17%'],
                    body: [
                        [{ text: 'Cognome', border: this.borderNoUp }, { text: dati.cognome, border: this.borderNoUp }, { text: 'Nome', border: this.borderNoUp }, { text: dati.nome, border: this.borderNoUp }, { text: 'C.F.', border: this.borderNoUp }, { text: dati.codfis, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [10]
            {
                table: {
                    widths: [55, '*', '18%', '32%', '8%', '10%'],
                    body: [
                        [{ text: 'Nato/a il', border: this.borderNoUp }, { text: moment(dati.natoil).format('DD/MM/YYYY'), border: this.borderNoUp }, { text: ['a ', { text: '(Comune o Stato estero)', italics: true }], border: this.borderNoUp }, { text: dati.natoa, border: this.borderNoUp }, { text: 'Provincia:', border: this.borderNoUp }, { text: dati.provnasc, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [11]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: ['R', { text: 'ESIDENZA O DOMICILIO ABITUALE (dati dichiarati dal lavoratore)', fontSize: 8 }], border: this.borderNoUp, style: 'header3' }],
                    ]
                },
                layout: this.tableLayout
            },
            // [12]
            {
                table: {
                    widths: [55, '*', '3%', '25%'],
                    body: [
                        [{ text: 'In via/piazza', border: this.borderNoUp }, { text: dati.indires, border: this.borderNoUp }, { text: 'n.', border: this.borderNoUp }, { text: dati.numres, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [13]
            {
                table: {
                    widths: [55, '*', '8%', '20%', '12%', '8%'],
                    body: [
                        [{ text: 'Comune', border: this.borderNoUp }, { text: dati.cittares, border: this.borderNoUp }, { text: 'CAP', border: this.borderNoUp }, { text: dati.capres, border: this.borderNoUp }, { text: 'Provincia', border: this.borderNoUp }, { text: dati.provres, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [14]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: ['R', { text: 'EPERIBILITÀ DURANTE LA MALATTIA (dati dichiarati dal lavoratore - da indicare solo se diversi da quelli di residenza o domicilio abituale riportati)', fontSize: 8 }], border: this.borderNoUp, style: 'header3' }]
                    ]
                },
                layout: this.tableLayout
            },
            // [15]
            {
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [{ text: ['Nominativo indicato presso l\'abitazione ', { text: '(se diverso dal proprio)', italics: true }], border: this.borderNoUp }, { text: dati.nomealt, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [16]
            {
                table: {
                    widths: [55, '*', '3%', '25%'],
                    body: [
                        [{ text: 'In via/piazza', border: this.borderNoUp }, { text: dati.indidom, border: this.borderNoUp }, { text: 'n.', border: this.borderNoUp }, { text: dati.numdom, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [17]
            {
                table: {
                    widths: [55, '*', '8%', '20%', '12%', '8%'],
                    body: [
                        [{ text: 'Comune', border: this.borderNoUp }, { text: dati.cittadom, border: this.borderNoUp }, { text: 'CAP', border: this.borderNoUp }, { text: dati.capdom, border: this.borderNoUp }, { text: 'Provincia', border: this.borderNoUp }, { text: dati.provdom, border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            },
            // [18]
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [{ text: 'Rilasciato ai sensi del DPCM 26 marzo 2008 e del Dlgs n. 250/2009', italics: true, fontSize: 10, bold: true, alignment: 'center', border: this.borderNoUp }]
                    ]
                },
                layout: this.tableLayout
            }
        ]
    };

    this.generaCertificato = function (callback) {

        let docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [42, 42, 42, 42],
            content: [
                this.contentCertificato(this.dati),
                { text: '', pageBreak: 'after' },
                this.contentAttestato(this.dati)
            ],
            defaultStyle: {
                font: 'Times',
                fontSize: 8
            },
            styles: {
                header1: {
                    font: 'Times',
                    fontSize: 15
                },
                header2: {
                    font: 'Times',
                    fontSize: 9,
                    bold: true
                },
                header3: {
                    font: 'Times',
                    fontSize: 9,
                    italics: true
                }
            }
        };

        pdfMake.fonts = this.fontDescriptors;

        try {

            let doc = pdfMake.createPdf(docDefinition);

            if (callback) {
                doc.getDataUrl(data => callback(data));
            } else {
                doc.download();
            }
        } catch (err) {
            console.error(err)
        }
    }
}
