// moduli.service.ts
// (c) 2017 EvoluS Srl
// (a) VG

function DocumentoTao(dati) {

    this.dati = dati;
    this.fontDescriptors = {
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf'
        },
        Times: {
            normal: 'times.ttf',
            bold: 'timesbd.ttf',
            italics: 'timesi.ttf',
            bolditalics: 'timesbi.ttf'
        },
        Arial: {
            normal: 'arial.ttf',
            bold: 'arialbd.ttf',
            italics: 'ariali.ttf',
            bolditalics: 'arialbi.ttf'
        },
        Bar3of9: {
            normal: 'fre3of9x.ttf',
            bold: 'fre3of9x.ttf',
            italics: 'fre3of9x.ttf',
            bolditalics: 'fre3of9x.ttf'
        }
    };
    this.emptyTableLayout = {
        hLineWidth: () => {
            return 0
        },
        vLineWidth: () => {
            return 0
        }
    };
    this.simpleTableLayout = {
        hLineWidth: function (i, node) {
            return (i === 0 || i === node.table.body.length) ? 1 : 0;
        },
        vLineWidth: function (i, node) {
            return (i === 0 || i === node.table.widths.length) ? 1 : 0;
        }
    };

    this.borderNoUp = [true, false, true, true];
    this.borderNone = [false, false, false, false];

    this.contentTao = () => {
        if (this.dati.settimane.length === 6) {
            let temp = this.dati.settimane[1];
            this.dati.settimane[1] = this.dati.settimane[2];
            this.dati.settimane[2] = temp;
            return this.getTao6();
        }
        if (this.dati.settimane.length === 5) {
            let temp = this.dati.settimane[1];
            this.dati.settimane[1] = this.dati.settimane[2];
            this.dati.settimane[2] = temp;
            return this.getTao5();
        }
        if (this.dati.settimane.length === 4) {
            let temp = this.dati.settimane[1];
            this.dati.settimane[1] = this.dati.settimane[2];
            this.dati.settimane[2] = temp;
            return this.getTao4();
        }
        if (this.dati.settimane.length === 3) {
            let temp = this.dati.settimane[1];
            this.dati.settimane[1] = this.dati.settimane[2];
            this.dati.settimane[2] = temp;
            return this.getTao3();
        }
        if (this.dati.settimane.length === 2) {
            this.dati.settimane[2] = this.dati.settimane[1];
            return this.getTao2();
        }
        if (this.dati.settimane.length === 1) {
            return this.getTao1();
        }
    };

    this.generaTao = function (callback) {

        let docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            pageMargins: [42, 42, 42, 42],
            content: [
                this.contentTao(),
            ],
            defaultStyle: {
                font: 'Times',
                fontSize: 10
            },
            styles: {
                header1: {
                    font: 'Times',
                    fontSize: 15
                },
                header2: {
                    font: 'Times',
                    fontSize: 12,
                    bold: true
                },
                header3: {
                    font: 'Times',
                    fontSize: 12,
                    italics: true
                }
            }
        };

        pdfMake.fonts = this.fontDescriptors;
        let doc = pdfMake.createPdf(docDefinition);

        if (callback) {
            doc.getDataUrl(data => callback(data));
        } else {
            doc.download();
        }

    },

        this.getTao6 = function () {
            return [
                // [0]
                {
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                {
                                    text: 'Prescrizione della Terapia Anticoagulante Orale',
                                    style: 'header1',
                                    alignment: 'center',
                                    bold: true
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout,
                },
                // [1]
                { text: '', margin: [0, 15, 0, 15] },
                // [2]
                {
                    table: {
                        widths: ['15%', '50%', '15%', '20%'],
                        style: 'blankTable',
                        body: [
                            [
                                { text: 'Paziente:' },
                                { text: this.dati.nomepaz, bold: true, style: 'header2', style: 'header2' },
                                {},
                                { text: 'Telefono: ' + this.dati.telefonopaz },
                            ],
                            [
                                { text: 'Diagnosi:' },
                                { text: this.dati.diagnosi },
                                {},
                                {}
                            ],
                            [
                                { text: 'Farmaco:' },
                                { text: this.dati.farmaco, style: 'header3', bold: true, style: 'header3', bold: true },
                                {},
                                {}
                            ],
                            [
                                { text: 'Data Controllo:' },
                                {
                                    text: [
                                        { text: this.dati.datacontrollo },
                                        { text: '\tINR: ' },
                                        { text: this.dati.inr, bold: true }
                                    ]
                                },
                                { text: 'Target: ' + this.dati.target },
                                {}
                            ],
                            [
                                { text: 'Note:' + this.dati.note, colSpan: 4 },
                                {},
                                {},
                                {}
                            ],
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [3]
                { text: '', margin: [0, 15, 0, 15] },
                // [4]
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 1
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[0].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsprimogiorno },
                                                    { text: this.dati.settimane[0].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgprimogiorno },
                                                    { text: this.dati.settimane[0].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssecondogiorno },
                                                    { text: this.dati.settimane[0].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsecondogiorno },
                                                    { text: this.dati.settimane[0].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsterzogiorno },
                                                    { text: this.dati.settimane[0].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgterzogiorno },
                                                    { text: this.dati.settimane[0].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquartogiorno },
                                                    { text: this.dati.settimane[0].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquartogiorno },
                                                    { text: this.dati.settimane[0].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquintogiorno },
                                                    { text: this.dati.settimane[0].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquintogiorno },
                                                    { text: this.dati.settimane[0].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssestogiorno },
                                                    { text: this.dati.settimane[0].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsestogiorno },
                                                    { text: this.dati.settimane[0].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssettimogiorno },
                                                    { text: this.dati.settimane[0].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsettimogiorno },
                                                    { text: this.dati.settimane[0].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 2
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[2].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsprimogiorno },
                                                    { text: this.dati.settimane[2].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgprimogiorno },
                                                    { text: this.dati.settimane[2].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssecondogiorno },
                                                    { text: this.dati.settimane[2].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsecondogiorno },
                                                    { text: this.dati.settimane[2].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsterzogiorno },
                                                    { text: this.dati.settimane[2].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgterzogiorno },
                                                    { text: this.dati.settimane[2].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquartogiorno },
                                                    { text: this.dati.settimane[2].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquartogiorno },
                                                    { text: this.dati.settimane[2].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquintogiorno },
                                                    { text: this.dati.settimane[2].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquintogiorno },
                                                    { text: this.dati.settimane[2].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssestogiorno },
                                                    { text: this.dati.settimane[2].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsestogiorno },
                                                    { text: this.dati.settimane[2].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssettimogiorno },
                                                    { text: this.dati.settimane[2].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsettimogiorno },
                                                    { text: this.dati.settimane[2].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ],
                                [
                                    // tabella 3
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[1].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsprimogiorno },
                                                    { text: this.dati.settimane[1].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgprimogiorno },
                                                    { text: this.dati.settimane[1].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssecondogiorno },
                                                    { text: this.dati.settimane[1].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsecondogiorno },
                                                    { text: this.dati.settimane[1].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsterzogiorno },
                                                    { text: this.dati.settimane[1].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgterzogiorno },
                                                    { text: this.dati.settimane[1].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquartogiorno },
                                                    { text: this.dati.settimane[1].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquartogiorno },
                                                    { text: this.dati.settimane[1].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquintogiorno },
                                                    { text: this.dati.settimane[1].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquintogiorno },
                                                    { text: this.dati.settimane[1].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssestogiorno },
                                                    { text: this.dati.settimane[1].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsestogiorno },
                                                    { text: this.dati.settimane[1].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssettimogiorno },
                                                    { text: this.dati.settimane[1].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsettimogiorno },
                                                    { text: this.dati.settimane[1].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 4
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[3].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsprimogiorno },
                                                    { text: this.dati.settimane[3].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgprimogiorno },
                                                    { text: this.dati.settimane[3].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssecondogiorno },
                                                    { text: this.dati.settimane[3].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsecondogiorno },
                                                    { text: this.dati.settimane[3].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsterzogiorno },
                                                    { text: this.dati.settimane[3].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgterzogiorno },
                                                    { text: this.dati.settimane[3].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsquartogiorno },
                                                    { text: this.dati.settimane[3].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgquartogiorno },
                                                    { text: this.dati.settimane[3].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsquintogiorno },
                                                    { text: this.dati.settimane[3].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgquintogiorno },
                                                    { text: this.dati.settimane[3].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssestogiorno },
                                                    { text: this.dati.settimane[3].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsestogiorno },
                                                    { text: this.dati.settimane[3].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssettimogiorno },
                                                    { text: this.dati.settimane[3].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsettimogiorno },
                                                    { text: this.dati.settimane[3].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ],
                            ]
                        ],
                    },
                    layout: this.emptyTableLayout
                },
                // [5]
                {
                    pageBreak: 'before',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 5
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[4].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsprimogiorno },
                                                    { text: this.dati.settimane[4].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgprimogiorno },
                                                    { text: this.dati.settimane[4].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gssecondogiorno },
                                                    { text: this.dati.settimane[4].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgsecondogiorno },
                                                    { text: this.dati.settimane[4].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsterzogiorno },
                                                    { text: this.dati.settimane[4].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgterzogiorno },
                                                    { text: this.dati.settimane[4].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsquartogiorno },
                                                    { text: this.dati.settimane[4].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgquartogiorno },
                                                    { text: this.dati.settimane[4].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsquintogiorno },
                                                    { text: this.dati.settimane[4].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgquintogiorno },
                                                    { text: this.dati.settimane[4].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gssestogiorno },
                                                    { text: this.dati.settimane[4].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgsestogiorno },
                                                    { text: this.dati.settimane[4].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gssettimogiorno },
                                                    { text: this.dati.settimane[4].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgsettimogiorno },
                                                    { text: this.dati.settimane[4].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 6
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[5].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[5].gsprimogiorno },
                                                    { text: this.dati.settimane[5].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[5].imgprimogiorno },
                                                    { text: this.dati.settimane[5].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[5].gssecondogiorno },
                                                    { text: this.dati.settimane[5].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[5].imgsecondogiorno },
                                                    { text: this.dati.settimane[5].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[5].gsterzogiorno },
                                                    { text: this.dati.settimane[5].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[5].imgterzogiorno },
                                                    { text: this.dati.settimane[5].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[5].gsquartogiorno },
                                                    { text: this.dati.settimane[5].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[5].imgquartogiorno },
                                                    { text: this.dati.settimane[5].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[5].gsquintogiorno },
                                                    { text: this.dati.settimane[5].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[5].imgquintogiorno },
                                                    { text: this.dati.settimane[5].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[5].gssestogiorno },
                                                    { text: this.dati.settimane[5].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[5].imgsestogiorno },
                                                    { text: this.dati.settimane[5].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[5].gssettimogiorno },
                                                    { text: this.dati.settimane[5].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[5].imgsettimogiorno },
                                                    { text: this.dati.settimane[5].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ]
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [6]
                {
                    margin: [0, 80, 0, 0],
                    table: {
                        widths: ['70%', '30%'],
                        body: [
                            [
                                { text: 'Prossimo controllo: \t\t' + this.dati.dataprossimocontrollo, bold: true, style: 'header2' },
                                {
                                    text: [
                                        { text: 'Il medico\n', alignment: 'center', },
                                        {
                                            text: 'Dott. ' + this.dati.nomemedico + '\n' + this.dati.codregmedico,
                                            bold: true, style: 'header2',
                                            alignment: 'center'
                                        }
                                    ]
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
            ];
        },

        this.getTao5 = function () {
            return [
                // [0]
                {
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                {
                                    text: 'Prescrizione della Terapia Anticoagulante Orale',
                                    style: 'header1',
                                    alignment: 'center',
                                    bold: true
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout,
                },
                // [1]
                { text: '', margin: [0, 15, 0, 15] },
                // [2]
                {
                    table: {
                        widths: ['15%', '50%', '15%', '20%'],
                        style: 'blankTable',
                        body: [
                            [
                                { text: 'Paziente:' },
                                { text: this.dati.nomepaz, bold: true, style: 'header2', style: 'header2' },
                                {},
                                { text: 'Telefono: ' + this.dati.telefonopaz },
                            ],
                            [
                                { text: 'Diagnosi:' },
                                { text: this.dati.diagnosi },
                                {},
                                {}
                            ],
                            [
                                { text: 'Farmaco:' },
                                { text: this.dati.farmaco, style: 'header3', bold: true, style: 'header3', bold: true },
                                {},
                                {}
                            ],
                            [
                                { text: 'Data Controllo:' },
                                {
                                    text: [
                                        { text: this.dati.datacontrollo },
                                        { text: '\tINR: ' },
                                        { text: this.dati.inr, bold: true }
                                    ]
                                },
                                { text: 'Target: ' + this.dati.target },
                                {}
                            ],
                            [
                                { text: 'Note:' + this.dati.note, colSpan: 4 },
                                {},
                                {},
                                {}
                            ],
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [3]
                { text: '', margin: [0, 15, 0, 15] },
                // [4]
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 1
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[0].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsprimogiorno },
                                                    { text: this.dati.settimane[0].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgprimogiorno },
                                                    { text: this.dati.settimane[0].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssecondogiorno },
                                                    { text: this.dati.settimane[0].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsecondogiorno },
                                                    { text: this.dati.settimane[0].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsterzogiorno },
                                                    { text: this.dati.settimane[0].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgterzogiorno },
                                                    { text: this.dati.settimane[0].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquartogiorno },
                                                    { text: this.dati.settimane[0].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquartogiorno },
                                                    { text: this.dati.settimane[0].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquintogiorno },
                                                    { text: this.dati.settimane[0].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquintogiorno },
                                                    { text: this.dati.settimane[0].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssestogiorno },
                                                    { text: this.dati.settimane[0].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsestogiorno },
                                                    { text: this.dati.settimane[0].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssettimogiorno },
                                                    { text: this.dati.settimane[0].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsettimogiorno },
                                                    { text: this.dati.settimane[0].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 2
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[2].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsprimogiorno },
                                                    { text: this.dati.settimane[2].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgprimogiorno },
                                                    { text: this.dati.settimane[2].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssecondogiorno },
                                                    { text: this.dati.settimane[2].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsecondogiorno },
                                                    { text: this.dati.settimane[2].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsterzogiorno },
                                                    { text: this.dati.settimane[2].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgterzogiorno },
                                                    { text: this.dati.settimane[2].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquartogiorno },
                                                    { text: this.dati.settimane[2].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquartogiorno },
                                                    { text: this.dati.settimane[2].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquintogiorno },
                                                    { text: this.dati.settimane[2].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquintogiorno },
                                                    { text: this.dati.settimane[2].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssestogiorno },
                                                    { text: this.dati.settimane[2].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsestogiorno },
                                                    { text: this.dati.settimane[2].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssettimogiorno },
                                                    { text: this.dati.settimane[2].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsettimogiorno },
                                                    { text: this.dati.settimane[2].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ],
                                [
                                    // tabella 3
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[1].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsprimogiorno },
                                                    { text: this.dati.settimane[1].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgprimogiorno },
                                                    { text: this.dati.settimane[1].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssecondogiorno },
                                                    { text: this.dati.settimane[1].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsecondogiorno },
                                                    { text: this.dati.settimane[1].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsterzogiorno },
                                                    { text: this.dati.settimane[1].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgterzogiorno },
                                                    { text: this.dati.settimane[1].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquartogiorno },
                                                    { text: this.dati.settimane[1].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquartogiorno },
                                                    { text: this.dati.settimane[1].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquintogiorno },
                                                    { text: this.dati.settimane[1].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquintogiorno },
                                                    { text: this.dati.settimane[1].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssestogiorno },
                                                    { text: this.dati.settimane[1].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsestogiorno },
                                                    { text: this.dati.settimane[1].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssettimogiorno },
                                                    { text: this.dati.settimane[1].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsettimogiorno },
                                                    { text: this.dati.settimane[1].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 4
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[3].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsprimogiorno },
                                                    { text: this.dati.settimane[3].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgprimogiorno },
                                                    { text: this.dati.settimane[3].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssecondogiorno },
                                                    { text: this.dati.settimane[3].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsecondogiorno },
                                                    { text: this.dati.settimane[3].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsterzogiorno },
                                                    { text: this.dati.settimane[3].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgterzogiorno },
                                                    { text: this.dati.settimane[3].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsquartogiorno },
                                                    { text: this.dati.settimane[3].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgquartogiorno },
                                                    { text: this.dati.settimane[3].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsquintogiorno },
                                                    { text: this.dati.settimane[3].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgquintogiorno },
                                                    { text: this.dati.settimane[3].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssestogiorno },
                                                    { text: this.dati.settimane[3].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsestogiorno },
                                                    { text: this.dati.settimane[3].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssettimogiorno },
                                                    { text: this.dati.settimane[3].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsettimogiorno },
                                                    { text: this.dati.settimane[3].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ],
                            ]
                        ],
                    },
                    layout: this.emptyTableLayout
                },
                // [5]
                {
                    pageBreak: 'before',
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 5
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[4].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsprimogiorno },
                                                    { text: this.dati.settimane[4].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgprimogiorno },
                                                    { text: this.dati.settimane[4].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gssecondogiorno },
                                                    { text: this.dati.settimane[4].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgsecondogiorno },
                                                    { text: this.dati.settimane[4].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsterzogiorno },
                                                    { text: this.dati.settimane[4].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgterzogiorno },
                                                    { text: this.dati.settimane[4].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsquartogiorno },
                                                    { text: this.dati.settimane[4].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgquartogiorno },
                                                    { text: this.dati.settimane[4].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gsquintogiorno },
                                                    { text: this.dati.settimane[4].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgquintogiorno },
                                                    { text: this.dati.settimane[4].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gssestogiorno },
                                                    { text: this.dati.settimane[4].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgsestogiorno },
                                                    { text: this.dati.settimane[4].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[4].gssettimogiorno },
                                                    { text: this.dati.settimane[4].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[4].imgsettimogiorno },
                                                    { text: this.dati.settimane[4].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ]
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [6]
                {
                    margin: [0, 80, 0, 0],
                    table: {
                        widths: ['70%', '30%'],
                        body: [
                            [
                                { text: 'Prossimo controllo: \t\t' + this.dati.dataprossimocontrollo, bold: true, style: 'header2' },
                                {
                                    text: [
                                        { text: 'Il medico\n', alignment: 'center', },
                                        {
                                            text: 'Dott. ' + this.dati.nomemedico + '\n' + this.dati.codregmedico,
                                            bold: true, style: 'header2',
                                            alignment: 'center'
                                        }
                                    ]
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
            ];
        },

        this.getTao4 = function () {
            return [
                // [0]
                {
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                {
                                    text: 'Prescrizione della Terapia Anticoagulante Orale',
                                    style: 'header1',
                                    alignment: 'center',
                                    bold: true
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout,
                },
                // [1]
                { text: '', margin: [0, 15, 0, 15] },
                // [2]
                {
                    table: {
                        widths: ['15%', '50%', '15%', '20%'],
                        style: 'blankTable',
                        body: [
                            [
                                { text: 'Paziente:' },
                                { text: this.dati.nomepaz, bold: true, style: 'header2', style: 'header2' },
                                {},
                                { text: 'Telefono: ' + this.dati.telefonopaz },
                            ],
                            [
                                { text: 'Diagnosi:' },
                                { text: this.dati.diagnosi },
                                {},
                                {}
                            ],
                            [
                                { text: 'Farmaco:' },
                                { text: this.dati.farmaco, style: 'header3', bold: true, style: 'header3', bold: true },
                                {},
                                {}
                            ],
                            [
                                { text: 'Data Controllo:' },
                                {
                                    text: [
                                        { text: this.dati.datacontrollo },
                                        { text: '\tINR: ' },
                                        { text: this.dati.inr, bold: true }
                                    ]
                                },
                                { text: 'Target: ' + this.dati.target },
                                {}
                            ],
                            [
                                { text: 'Note:' + this.dati.note, colSpan: 4 },
                                {},
                                {},
                                {}
                            ],
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [3]
                { text: '', margin: [0, 15, 0, 15] },
                // [4]
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 1
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[0].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsprimogiorno },
                                                    { text: this.dati.settimane[0].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgprimogiorno },
                                                    { text: this.dati.settimane[0].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssecondogiorno },
                                                    { text: this.dati.settimane[0].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsecondogiorno },
                                                    { text: this.dati.settimane[0].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsterzogiorno },
                                                    { text: this.dati.settimane[0].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgterzogiorno },
                                                    { text: this.dati.settimane[0].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquartogiorno },
                                                    { text: this.dati.settimane[0].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquartogiorno },
                                                    { text: this.dati.settimane[0].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquintogiorno },
                                                    { text: this.dati.settimane[0].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquintogiorno },
                                                    { text: this.dati.settimane[0].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssestogiorno },
                                                    { text: this.dati.settimane[0].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsestogiorno },
                                                    { text: this.dati.settimane[0].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssettimogiorno },
                                                    { text: this.dati.settimane[0].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsettimogiorno },
                                                    { text: this.dati.settimane[0].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 2
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[2].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsprimogiorno },
                                                    { text: this.dati.settimane[2].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgprimogiorno },
                                                    { text: this.dati.settimane[2].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssecondogiorno },
                                                    { text: this.dati.settimane[2].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsecondogiorno },
                                                    { text: this.dati.settimane[2].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsterzogiorno },
                                                    { text: this.dati.settimane[2].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgterzogiorno },
                                                    { text: this.dati.settimane[2].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquartogiorno },
                                                    { text: this.dati.settimane[2].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquartogiorno },
                                                    { text: this.dati.settimane[2].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquintogiorno },
                                                    { text: this.dati.settimane[2].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquintogiorno },
                                                    { text: this.dati.settimane[2].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssestogiorno },
                                                    { text: this.dati.settimane[2].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsestogiorno },
                                                    { text: this.dati.settimane[2].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssettimogiorno },
                                                    { text: this.dati.settimane[2].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsettimogiorno },
                                                    { text: this.dati.settimane[2].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ],
                                [
                                    // tabella 3
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[1].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsprimogiorno },
                                                    { text: this.dati.settimane[1].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgprimogiorno },
                                                    { text: this.dati.settimane[1].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssecondogiorno },
                                                    { text: this.dati.settimane[1].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsecondogiorno },
                                                    { text: this.dati.settimane[1].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsterzogiorno },
                                                    { text: this.dati.settimane[1].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgterzogiorno },
                                                    { text: this.dati.settimane[1].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquartogiorno },
                                                    { text: this.dati.settimane[1].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquartogiorno },
                                                    { text: this.dati.settimane[1].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquintogiorno },
                                                    { text: this.dati.settimane[1].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquintogiorno },
                                                    { text: this.dati.settimane[1].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssestogiorno },
                                                    { text: this.dati.settimane[1].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsestogiorno },
                                                    { text: this.dati.settimane[1].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssettimogiorno },
                                                    { text: this.dati.settimane[1].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsettimogiorno },
                                                    { text: this.dati.settimane[1].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 4
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[3].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsprimogiorno },
                                                    { text: this.dati.settimane[3].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgprimogiorno },
                                                    { text: this.dati.settimane[3].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssecondogiorno },
                                                    { text: this.dati.settimane[3].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsecondogiorno },
                                                    { text: this.dati.settimane[3].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsterzogiorno },
                                                    { text: this.dati.settimane[3].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgterzogiorno },
                                                    { text: this.dati.settimane[3].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsquartogiorno },
                                                    { text: this.dati.settimane[3].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgquartogiorno },
                                                    { text: this.dati.settimane[3].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gsquintogiorno },
                                                    { text: this.dati.settimane[3].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgquintogiorno },
                                                    { text: this.dati.settimane[3].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssestogiorno },
                                                    { text: this.dati.settimane[3].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsestogiorno },
                                                    { text: this.dati.settimane[3].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[3].gssettimogiorno },
                                                    { text: this.dati.settimane[3].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[3].imgsettimogiorno },
                                                    { text: this.dati.settimane[3].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ]
                            ]
                        ],
                    },
                    layout: this.emptyTableLayout
                },
                // [5]
                {
                    margin: [0, 80, 0, 0],
                    table: {
                        widths: ['70%', '30%'],
                        body: [
                            [
                                { text: 'Prossimo controllo: \t\t' + this.dati.dataprossimocontrollo, bold: true, style: 'header2' },
                                {
                                    text: [
                                        { text: 'Il medico\n', alignment: 'center', },
                                        {
                                            text: 'Dott. ' + this.dati.nomemedico + '\n' + this.dati.codregmedico,
                                            bold: true, style: 'header2',
                                            alignment: 'center'
                                        }
                                    ]
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
            ];
        },
        this.getTao3 = function () {
            return [
                // [0]
                {
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                {
                                    text: 'Prescrizione della Terapia Anticoagulante Orale',
                                    style: 'header1',
                                    alignment: 'center',
                                    bold: true
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout,
                },
                // [1]
                { text: '', margin: [0, 15, 0, 15] },
                // [2]
                {
                    table: {
                        widths: ['15%', '50%', '15%', '20%'],
                        style: 'blankTable',
                        body: [
                            [
                                { text: 'Paziente:' },
                                { text: this.dati.nomepaz, bold: true, style: 'header2' },
                                {},
                                { text: 'Telefono: ' + this.dati.telefonopaz },
                            ],
                            [
                                { text: 'Diagnosi:' },
                                { text: this.dati.diagnosi },
                                {},
                                {}
                            ],
                            [
                                { text: 'Farmaco:' },
                                { text: this.dati.farmaco, style: 'header3', bold: true },
                                {},
                                {}
                            ],
                            [
                                { text: 'Data Controllo:' },
                                {
                                    text: [
                                        { text: this.dati.datacontrollo },
                                        { text: '\tINR: ' },
                                        { text: this.dati.inr, bold: true }
                                    ]
                                },
                                { text: 'Target: ' + this.dati.target },
                                {}
                            ],
                            [
                                { text: 'Note:' + this.dati.note, colSpan: 4 },
                                {},
                                {},
                                {}
                            ],
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [3]
                { text: '', margin: [0, 15, 0, 15] },
                // [4]
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 1
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[0].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsprimogiorno },
                                                    { text: this.dati.settimane[0].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgprimogiorno },
                                                    { text: this.dati.settimane[0].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssecondogiorno },
                                                    { text: this.dati.settimane[0].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsecondogiorno },
                                                    { text: this.dati.settimane[0].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsterzogiorno },
                                                    { text: this.dati.settimane[0].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgterzogiorno },
                                                    { text: this.dati.settimane[0].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquartogiorno },
                                                    { text: this.dati.settimane[0].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquartogiorno },
                                                    { text: this.dati.settimane[0].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquintogiorno },
                                                    { text: this.dati.settimane[0].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquintogiorno },
                                                    { text: this.dati.settimane[0].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssestogiorno },
                                                    { text: this.dati.settimane[0].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsestogiorno },
                                                    { text: this.dati.settimane[0].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssettimogiorno },
                                                    { text: this.dati.settimane[0].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsettimogiorno },
                                                    { text: this.dati.settimane[0].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 2
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[2].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsprimogiorno },
                                                    { text: this.dati.settimane[2].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgprimogiorno },
                                                    { text: this.dati.settimane[2].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssecondogiorno },
                                                    { text: this.dati.settimane[2].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsecondogiorno },
                                                    { text: this.dati.settimane[2].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsterzogiorno },
                                                    { text: this.dati.settimane[2].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgterzogiorno },
                                                    { text: this.dati.settimane[2].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquartogiorno },
                                                    { text: this.dati.settimane[2].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquartogiorno },
                                                    { text: this.dati.settimane[2].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquintogiorno },
                                                    { text: this.dati.settimane[2].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquintogiorno },
                                                    { text: this.dati.settimane[2].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssestogiorno },
                                                    { text: this.dati.settimane[2].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsestogiorno },
                                                    { text: this.dati.settimane[2].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssettimogiorno },
                                                    { text: this.dati.settimane[2].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsettimogiorno },
                                                    { text: this.dati.settimane[2].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ],
                                [
                                    // tabella 3
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[1].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsprimogiorno },
                                                    { text: this.dati.settimane[1].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgprimogiorno },
                                                    { text: this.dati.settimane[1].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssecondogiorno },
                                                    { text: this.dati.settimane[1].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsecondogiorno },
                                                    { text: this.dati.settimane[1].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsterzogiorno },
                                                    { text: this.dati.settimane[1].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgterzogiorno },
                                                    { text: this.dati.settimane[1].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquartogiorno },
                                                    { text: this.dati.settimane[1].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquartogiorno },
                                                    { text: this.dati.settimane[1].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gsquintogiorno },
                                                    { text: this.dati.settimane[1].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgquintogiorno },
                                                    { text: this.dati.settimane[1].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssestogiorno },
                                                    { text: this.dati.settimane[1].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsestogiorno },
                                                    { text: this.dati.settimane[1].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[1].gssettimogiorno },
                                                    { text: this.dati.settimane[1].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[1].imgsettimogiorno },
                                                    { text: this.dati.settimane[1].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ]
                            ]
                        ],
                    },
                    layout: this.emptyTableLayout
                },
                // [5]
                {
                    margin: [0, 80, 0, 0],
                    table: {
                        widths: ['70%', '30%'],
                        body: [
                            [
                                { text: 'Prossimo controllo: \t\t' + this.dati.dataprossimocontrollo, bold: true, style: 'header2' },
                                {
                                    text: [
                                        { text: 'Il medico\n', alignment: 'center', },
                                        {
                                            text: 'Dott. ' + this.dati.nomemedico + '\n' + this.dati.codregmedico,
                                            bold: true, style: 'header2',
                                            alignment: 'center'
                                        }
                                    ]
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
            ];
        },
        this.getTao2 = function () {
            return [
                // [0]
                {
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                {
                                    text: 'Prescrizione della Terapia Anticoagulante Orale',
                                    style: 'header1',
                                    alignment: 'center',
                                    bold: true
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout,
                },
                // [1]
                { text: '', margin: [0, 15, 0, 15] },
                // [2]
                {
                    table: {
                        widths: ['15%', '50%', '15%', '20%'],
                        style: 'blankTable',
                        body: [
                            [
                                { text: 'Paziente:' },
                                { text: this.dati.nomepaz, bold: true, style: 'header2' },
                                {},
                                { text: 'Telefono: ' + this.dati.telefonopaz },
                            ],
                            [
                                { text: 'Diagnosi:' },
                                { text: this.dati.diagnosi },
                                {},
                                {}
                            ],
                            [
                                { text: 'Farmaco:' },
                                { text: this.dati.farmaco, style: 'header3', bold: true, style: 'header3', bold: true },
                                {},
                                {}
                            ],
                            [
                                { text: 'Data Controllo:' },
                                {
                                    text: [
                                        { text: this.dati.datacontrollo },
                                        { text: '\tINR: ' },
                                        { text: this.dati.inr, bold: true }
                                    ]
                                },
                                { text: 'Target: ' + this.dati.target },
                                {}
                            ],
                            [
                                { text: 'Note:' + this.dati.note, colSpan: 4 },
                                {},
                                {},
                                {}
                            ],
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [3]
                { text: '', margin: [0, 15, 0, 15] },
                // [4]
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 1
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[0].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsprimogiorno },
                                                    { text: this.dati.settimane[0].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgprimogiorno },
                                                    { text: this.dati.settimane[0].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssecondogiorno },
                                                    { text: this.dati.settimane[0].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsecondogiorno },
                                                    { text: this.dati.settimane[0].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsterzogiorno },
                                                    { text: this.dati.settimane[0].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgterzogiorno },
                                                    { text: this.dati.settimane[0].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquartogiorno },
                                                    { text: this.dati.settimane[0].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquartogiorno },
                                                    { text: this.dati.settimane[0].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquintogiorno },
                                                    { text: this.dati.settimane[0].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquintogiorno },
                                                    { text: this.dati.settimane[0].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssestogiorno },
                                                    { text: this.dati.settimane[0].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsestogiorno },
                                                    { text: this.dati.settimane[0].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssettimogiorno },
                                                    { text: this.dati.settimane[0].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsettimogiorno },
                                                    { text: this.dati.settimane[0].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    },
                                    // tabella 2
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    { text: "Dose settimanale: " + this.dati.settimane[2].dosesettimanale, colSpan: 3, bold: true },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsprimogiorno },
                                                    { text: this.dati.settimane[2].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgprimogiorno },
                                                    { text: this.dati.settimane[2].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssecondogiorno },
                                                    { text: this.dati.settimane[2].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsecondogiorno },
                                                    { text: this.dati.settimane[2].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsterzogiorno },
                                                    { text: this.dati.settimane[2].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgterzogiorno },
                                                    { text: this.dati.settimane[2].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquartogiorno },
                                                    { text: this.dati.settimane[2].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquartogiorno },
                                                    { text: this.dati.settimane[2].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gsquintogiorno },
                                                    { text: this.dati.settimane[2].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgquintogiorno },
                                                    { text: this.dati.settimane[2].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssestogiorno },
                                                    { text: this.dati.settimane[2].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsestogiorno },
                                                    { text: this.dati.settimane[2].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[2].gssettimogiorno },
                                                    { text: this.dati.settimane[2].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[2].imgsettimogiorno },
                                                    { text: this.dati.settimane[2].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ]
                            ]
                        ],
                    },
                    layout: this.emptyTableLayout
                },
                // [5]
                {
                    margin: [0, 80, 0, 0],
                    table: {
                        widths: ['70%', '30%'],
                        body: [
                            [
                                { text: 'Prossimo controllo: \t\t' + this.dati.dataprossimocontrollo, bold: true, style: 'header2' },
                                {
                                    text: [
                                        { text: 'Il medico\n', alignment: 'center', },
                                        {
                                            text: 'Dott. ' + this.dati.nomemedico + '\n' + this.dati.codregmedico,
                                            bold: true,
                                            alignment: 'center'
                                        }
                                    ]
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
            ];
        },
        this.getTao1 = function () {
            return [
                // [0]
                {
                    table: {
                        widths: ['100%'],
                        body: [
                            [
                                {
                                    text: 'Prescrizione della Terapia Anticoagulante Orale',
                                    style: 'header1',
                                    alignment: 'center',
                                    bold: true
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout,
                },
                // [1]
                { text: '', margin: [0, 15, 0, 15] },
                // [2]
                {
                    table: {
                        widths: ['15%', '50%', '15%', '20%'],
                        style: 'blankTable',
                        body: [
                            [
                                { text: 'Paziente:' },
                                { text: this.dati.nomepaz, bold: true, style: 'header2' },
                                {},
                                { text: 'Telefono: ' + this.dati.telefonopaz },
                            ],
                            [
                                { text: 'Diagnosi:' },
                                { text: this.dati.diagnosi },
                                {},
                                {}
                            ],
                            [
                                { text: 'Farmaco:' },
                                { text: this.dati.farmaco, style: 'header3', bold: true },
                                {},
                                {}
                            ],
                            [
                                { text: 'Data Controllo:' },
                                {
                                    text: [
                                        { text: this.dati.datacontrollo },
                                        { text: '\tINR: ' },
                                        { text: this.dati.inr, bold: true }
                                    ]
                                },
                                { text: 'Target: ' + this.dati.target },
                                {}
                            ],
                            [
                                { text: 'Note:' + this.dati.note, colSpan: 4 },
                                {},
                                {},
                                {}
                            ],
                        ]
                    },
                    layout: this.emptyTableLayout
                },
                // [3]
                { text: '', margin: [0, 15, 0, 15] },
                // [4]
                {
                    table: {
                        widths: ['50%', '50%'],
                        body: [
                            [
                                [
                                    // tabella 1
                                    {
                                        table: {
                                            widths: ["25%", "25%", "25%", "25%"],
                                            body: [
                                                [
                                                    {
                                                        text: "Dose settimanale: " + this.dati.settimane[0].dosesettimanale,
                                                        colSpan: 2,
                                                        bold: true
                                                    },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsprimogiorno },
                                                    { text: this.dati.settimane[0].primogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgprimogiorno },
                                                    { text: this.dati.settimane[0].doseprimogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssecondogiorno },
                                                    { text: this.dati.settimane[0].secondogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsecondogiorno },
                                                    { text: this.dati.settimane[0].dosesecondogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsterzogiorno },
                                                    { text: this.dati.settimane[0].terzogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgterzogiorno },
                                                    { text: this.dati.settimane[0].doseterzogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquartogiorno },
                                                    { text: this.dati.settimane[0].quartogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquartogiorno },
                                                    { text: this.dati.settimane[0].dosequartogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gsquintogiorno },
                                                    { text: this.dati.settimane[0].quintogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgquintogiorno },
                                                    { text: this.dati.settimane[0].dosequintogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssestogiorno },
                                                    { text: this.dati.settimane[0].sestogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsestogiorno },
                                                    { text: this.dati.settimane[0].dosesestogiorno }
                                                ],
                                                [
                                                    { text: this.dati.settimane[0].gssettimogiorno },
                                                    { text: this.dati.settimane[0].settimogiorno },
                                                    { image: 'data:image/png;base64,' + this.dati.settimane[0].imgsettimogiorno },
                                                    { text: this.dati.settimane[0].dosesettimogiorno }
                                                ]
                                            ],
                                        },
                                        layout: this.simpleTableLayout,
                                        margin: [0, 15, 0, 15]
                                    }
                                ]
                            ]
                        ],
                    },
                    layout: this.emptyTableLayout
                },
                // [5]
                {
                    margin: [0, 250, 0, 0],
                    table: {
                        widths: ['70%', '30%'],
                        body: [
                            [
                                { text: 'Prossimo controllo: \t\t' + this.dati.dataprossimocontrollo, bold: true, style: 'header2' },
                                {
                                    text: [
                                        { text: 'Il medico\n', alignment: 'center', },
                                        {
                                            text: 'Dott. ' + this.dati.nomemedico + '\n' + this.dati.codregmedico,
                                            bold: true,
                                            alignment: 'center'
                                        }
                                    ]
                                },
                            ]
                        ]
                    },
                    layout: this.emptyTableLayout
                },
            ];
        }
}
