function toString(val) {
  if (val === undefined || val === null) {
    return '';
  } else {
    return val;
  }
}

class DocumentoPrivacy {

  constructor(medico, funzionalita, utente, natoa, natoil, cf) {

    this.medico = medico;
    this.funzionalita = funzionalita;
    this.utente = utente;
    this.natoa = natoa;
    this.natoil = natoil;
    this.cf = cf;

    // verifico se maggiorenne
    this.maggiorenne = true;
    // metto in un try catch perchè la data e il luogo di nascita potrebbero non essere presenti
    try {
      let annoNascita = this.natoil.split('/')[2];
      let annoAttuale = new Date().getFullYear();
      if (parseInt(annoNascita) + 18 > annoAttuale) {
        this.maggiorenne = false;
      }
    } catch (err) {

    }


    this.fontDescriptors = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      },
      Times: {
        normal: 'times.ttf',
        bold: 'timesbd.ttf',
        italics: 'timesi.ttf',
        bolditalics: 'timesbi.ttf'
      },
      Arial: {
        normal: 'arial.ttf',
        bold: 'arialbd.ttf',
        italics: 'ariali.ttf',
        bolditalics: 'arialbi.ttf'
      },
      Bar3of9: {
        normal: 'fre3of9x.ttf',
        bold: 'fre3of9x.ttf',
        italics: 'fre3of9x.ttf',
        bolditalics: 'fre3of9x.ttf'
      }
    };
    this.tableLayout = {
      hLineWidth: () => {
        return 0.7
      },
      vLineWidth: () => {
        return 0.7
      }
    };
    this.borderNoUp = [true, false, true, true];
    this.borderNone = [false, false, false, false];
  }

  spazio(height = 1) {
    return { text: ' ', lineHeight: height };
  }

  stampa(filename, callback) {

    const nomeApplicazione = localStorage.getItem('saluteconte.nome-applicazione')

    const spazio = this.spazio; // utility

    // oggetto funzionalità
    let funzionalitaDoc = [];
    this.funzionalita.forEach(fun => {
      funzionalitaDoc.push({
        text: fun, bold: true
      });
    });
    // oggetto minorenne/maggiorenne
    let maggiorenneMinorenne;
    if (this.maggiorenne) {
      maggiorenneMinorenne = {
        text: 'Con la presente, io sottoscritto ' + this.utente
      };
    } else {
      maggiorenneMinorenne = {
        text: 'Nella mia qualità di genitore di ' + this.utente
      };
    }
    // oggetto firma
    let testoFirmaMaggiorenneMinorenne;
    if (this.maggiorenne) {
      testoFirmaMaggiorenneMinorenne = 'Firma del Paziente richiedente';
    } else {
      testoFirmaMaggiorenneMinorenne = 'Firma del genitore titolare della responsabilità genitoriale';
    }

    let docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [42, 42, 42, 42],
      content: [
        {
          text: 'INFORMATIVA SUL PORTALE "' + nomeApplicazione.toUpperCase() + '" E RICHIESTA DI ATTIVAZIONE',
          decoration: 'underline',
          bold: true,
          alignment: 'center'
        },
        spazio(2),
        {
          text: [
            'Il sottoscritto Dott. ',
            { text: this.medico, bold: true }
          ]
        },
        spazio(),
        {
          text: 'COMUNICA CHE', decoration: 'underline', bold: true, alignment: 'center'
        },
        spazio(),
        {
          ol: [
            "È disponibile per tutti i Pazienti che abbiano compiuto i 14 anni di età un nuovo servizio che consente l'attivazione, per ogni Paziente e su sua richiesta, di un proprio account sul Portale \"" + nomeApplicazione + "\".",
            "L'attivazione dell'account fornisce al Paziente la possibilità, previo pagamento di un canone di abbonamento annuale (dal 1° gennaio al 31 dicembre di ogni anno), da corrispondere direttamente al fornitore del servizio in occasione del primo accesso, di utilizzare un canale di comunicazione privilegiato con il sottoscritto o con eventuali medici sostituti.",
            "Il canone di abbonamento annuale è corrisposto integralmente al fornitore del servizio; il sottoscritto non trae dall'attivazione dell'account alcun vantaggio di tipo economico.",
            "L'account sul Portale \"" + nomeApplicazione + "\" comprenderà i seguenti servizi:"
          ]
        },
        spazio(),
        {
          ol: funzionalitaDoc, alignment: "center", type: "none"
        },
        spazio(),
        {
          ol: [
            "La scelta dei servizi disponibili è effettuata discrezionalmente dal sottoscritto, il quale garantisce che tali servizi resteranno disponibili sino al termine dell'anno solare in corso al momento dell'abbonamento al Portale \"" + nomeApplicazione + "\".",
            "Ove il sottoscritto ritenesse di variare la quantità e/o la qualità dei servizi sopra descritti, tali variazioni Le saranno comunicate con un preavviso di 20 gg. dalla scadenza del Suo abbonamento annuale, cosicché Lei possa scegliere liberamente se rinnovare o meno tale abbonamento.",
            { text: "Il Portale costituisce un mezzo di comunicazione privilegiato per le funzioni di cui sopra,ma non può essere garantita l'immediata consultazione di tutte le Sue comunicazioni." },
            "In ogni caso, il Portale non costituisce l'unico canale di comunicazione con il sottoscritto; restano infatti perfettamente operativi anche i canali di comunicazione precedentemente da Lei utilizzati.",
            "Nel caso in cui Lei dovesse manifestare sintomi inusuali o ravvisare problemi di salute che richiedono trattamenti urgenti, oppure riscontrare, nei referti delle Sue analisi, valori superiori alle soglie di pericolo, ove non riuscisse a prendere immediatamente contatto con il sottoscritto mediante canali di comunicazione diversi dal Portale, dovrà immediatamente rivolgersi al Pronto Soccorso più vicino, oppure alla Guardia Medica, oppure ancora al numero di emergenza 112.",
            "Il sottoscritto non si assume alcuna responsabilità in caso di mancato recepimento di comunicazioni o richieste da Lei trasmesse per il tramite del Portale in giorni festivi, nel periodo di ferie, oppure in orari diversi dall'orario di visita consultabile presso lo Studio.",
            "L'attivazione del servizio potrà essere effettuata previa Sua autorizzazione al trattamento dei dati mediante sottoscrizione dell'informativa qui allegata."
          ],
          start: "5"
        },
        spazio(4),
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: 'Luogo e data', border: [false, false, false, false], alignment: 'left'
                },
                {
                  text: 'Dott. ' + this.medico,
                  border: [false, false, false, false],
                  alignment: 'right'
                }
              ]
            ],
          }
        },
        spazio(),
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: ' ', border: [false, false, false, false]
                },
                {
                  text: 'Firma', border: [false, false, false, false], alignment: 'center'
                }
              ]
            ],
          }
        },

        { text: '', pageBreak: 'after' },

        maggiorenneMinorenne,
        {
          text: this.natoa ? 'nato a ' + this.natoa : ''
        },
        {
          text: this.natoil ? 'il ' + this.natoil + ', C.F. ' + this.cf : ''
        },
        spazio(),
        {
          alignment: 'center',
          text: [
            { text: 'preso atto ed accettato', bold: true },
            ' tutto quanto sopra dichiarato dal'
          ]
        },
        spazio(),
        {
          text: 'Dott. ' + this.medico, alignment: 'center'
        },
        spazio(2),
        {
          text: 'RICHIEDO', decoration: 'underline', bold: true, alignment: 'center'
        },
        spazio(),
        {
          text: 'al Dott. ' + this.medico, alignment: 'center'
        },
        spazio(4),
        {
          text: "l'attivazione del Portale \"" + nomeApplicazione + "\" e dei relativi servizi sopra descritti, nonché la consegna delle credenziali necessarie per effettuare il primo accesso al Portale medesimo."
        },
        spazio(2),
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: 'Luogo, data', border: [false, false, false, false], alignment: 'left'
                },
                {
                  text: testoFirmaMaggiorenneMinorenne,
                  border: [false, false, false, false],
                  alignment: 'right'
                }
              ]
            ],
            border: [false, false, false, false]
          }
        },
        spazio(4),
        {
          text: "Il sottoscritto approva specificamente le clausole 7 e 10 dell'informativa relative alle limitazioni di responsabilità."
        },
        spazio(2),
        {
          text: testoFirmaMaggiorenneMinorenne, alignment: 'right'
        }
      ],
      defaultStyle: {
        font: 'Times',
        fontSize: 10
      },
      styles: {
        header1: {
          font: 'Times',
          fontSize: 15
        },
        header2: {
          font: 'Times',
          fontSize: 9,
          bold: true
        },
        header3: {
          font: 'Times',
          fontSize: 9,
          italics: true
        }
      }
    };

    pdfMake.fonts = this.fontDescriptors;

    let doc = pdfMake.createPdf(docDefinition);

    if (callback) {
      doc.getDataUrl(data => callback(data));
    } else {
      doc.download(filename);
    }
  }
}
